import React, { useEffect } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';

const PageError = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	useEffect(() => {
		const storedTheme = localStorage.getItem('theme');
		if (storedTheme !== null) {
			setDarkModeStatus(storedTheme === 'dark');
		}
	}, [setDarkModeStatus]);

	return (
		<div
			style={{
				backgroundImage: darkModeStatus
					? 'url(/image/oops.jpg)'
					: 'url(/image/oops-light.jpg)',
				backgroundSize: 'cover',
				width: '100%',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: darkModeStatus ? '#242632' : '#ececec',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			}}>
			<Button
				className='px-5 py-3'
				color='primary'
				isLight
				icon='Home'
				tag='a'
				href='/dashboard'
				style={{
					position: 'absolute',
					bottom: '20%',
					fontSize: '1.2rem',
				}}>
				Go Back to Homepage
			</Button>
		</div>
	);
};

export default PageError;
